<style lang="less" scoped>
  .contents {
    .banner-box {
      .banner {
        object-fit: contain;
      }
    }
  }
</style>

<template>
    <!-- id : contents : (S) -->
    <div id='contents' class="st-login" style="background-color: white" >
        <!-- class : content-wrap : (S) -->
        <section class="content-wrap">
            <!-- class : main-wrap (S) -->
            <div class="main-wrap">
                <div class="login-wrap" >
                    <h2>로그인</h2>
                    <div class="login-form-box">
                        <div class="input-box"><input type="text" placeholder="아이디" v-model="username" @keydown="onKeydown"></div>
                        <div class="input-box"><input type="password" placeholder="비밀번호" v-model="password" @keydown="onKeydown"></div>
                        <div class="check-box" style="margin-bottom: 22px;margin-top: 15px;"><input type="checkbox" class="chk-st1" id="chk-0101" v-model="remember"><label for="chk-0101">아이디 저장하기</label></div>
                        <div class="btn-box"><a href="javascript:;" @click="handleLogin" class="" v-bind:disabled="isButtonDisabled">로그인</a></div>
                    </div>
                    <div class="find-box pc-only">
                        <div class="row-box">
                            <span class="">아이디를 잊으셨나요?</span>
                            <a href="javascript:;" @click="onClickFindId">아이디 찾기</a>
                        </div>
                        <div class="row-box">
                            <span class="../member/login_findId_phone.html">비밀번호를 잊으셨나요?</span>
                            <a href="javascript:;" @click="onClickFindPwdId">비밀번호 재설정</a>
                        </div>
                        <div class="join-box">
                          <a href="javascript:;" class="guide-btn" @click="onClickGuide">e-CLUB 가이드</a>
                          <a href="javascript:;" class="join-btn" @click="onClickJoin">회원가입</a>
                        </div>
                    </div>
                    <div class="mb-only">
                      <div class="mb-login-btn-box">
                        <a href="javascript:;" @click="onClickFindId">아이디 찾기</a>
                        <a href="javascript:;" @click="onClickFindPwdId">비밀번호 재설정</a>
                        <a href="javascript:;" @click="onClickJoin">회원가입</a>
                      </div>
                      <div class="mb-login-guide-btn-box">
                        <em>e-club 소개</em><a href="javascript:;" @click="onClickGuide">신규회원을 위한 전자사랑 가이드</a>
                      </div>
                    </div>
                </div>
                <div class="banner-box" >
                   <a v-for="banner in banners" :key="banner.bannerId" style="cursor: pointer">
                     <img
                      class="banner"
                      v-if="banner.activated"
                      :src="`/attachment/banner/${banner.bannerImage}`"
                      @click="onClickBanner(banner.url)" style="width:490px;"/>
                   </a>
                </div>
            </div>
            <!-- class : main-wrap (E) -->
        </section>
        <!-- class : content-wrap : (E) -->
    </div>
    <!-- id : contents : (E) -->
</template>

<script>
import moment from 'moment';
import BannerApi from '@/api/BannerApi'
export default {
  name: 'login',
  components: {
  },
  data() {
    const username = this.$cookies.get('saved-username') || null;
    return {
      isButtonDisabled: false,
      banners:[],

      username,
      password: '',
      remember: !!username,
    }
  },
  created() {
    //console.log( '** ' + JSON.stringify(this.$store.state.user) )
    this.getBanners();
  }, 
  methods: {
    getBanners(){
      let params = {
        page: 1
      };
      BannerApi.list('TYPE_LOGIN', params).then(data => {
        this.banners = data.content;
      });
    },
    onClickFindId(){
      this.$router.push(`/find/findId`)
    },
    onClickFindPwdId(){
      this.$router.push(`/find/findPwdId`)
    },
    onClickJoin(){
      this.$router.push(`/join`)
    },
    onClickGuide() {
      this.$router.push(`/loginGuide`)
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.handleLogin();
      }
    },
    handleLogin() {
      if (this.username == null || this.username == '') {
        return alert('아이디를 입력해주세요.')
      } else if (this.password == '') {
        return alert('비밀번호를 입력해주세요.')
      }
      this.$store.dispatch('user/login', {
        username: this.username,
        password: this.password,
      }).then(success => {
        if (success) {
          const expireTimes = moment().add(10, 'years');
          if (this.remember) this.$cookies.set('saved-username', this.username, expireTimes.toDate());
          else this.$cookies.remove('saved-username');
          this.$emit('click:login', true)
          this.$router.push('/main');
        }
      }).catch(function (error) {
        if (error.response) {
          let errorMsg = '사용자 계정이 인증 되지 않았습니다. 메일 확인하시기 바랍니다.';
          if (error.response.data.error_description == errorMsg) {
            alert('승인 확인 중인 아이디입니다.')
          } else {
            alert(error.response.data.error_description)
          }
        }
      });
    },
    onClickBanner(url){
      if(url != null && url != '') {
        window.open(url, '', '');
      }
    }

  }
}
</script>